import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import PartnershipPage from '../templates/Partnership';

const partnership = {
  header: {
    title: 'partnershipTitle',
    subTitle: 'partnershipSubTitle',
    image: 'hands.png',
  },
  corezoidPluses: {
    title: 'partnershipPlusesTitle',
    list: [
      {
        title: 'partnershipPlusesEcoTitle',
        subTitle: 'partnershipPlusesEcoSubTitle',
        image: 'magic.png',
      },
      {
        title: 'partnershipPlusesProtoTitle',
        subTitle: 'partnershipPlusesProtoSubTitle',
        image: 'pan.png',
      },
      {
        title: 'partnershipPlusesTimeTitle',
        subTitle: 'partnershipPlusesTimeSubTitle',
        image: 'clock.png',
      },
      {
        title: 'partnershipPlusesHostTitle',
        subTitle: 'partnershipPlusesHostSubTitle',
        image: 'button.png',
      },
    ]
  },
  partners: {
    title: 'partnershipPartnersTitle',
    list: [
      {
        title: 'Sigma Software',
        subTitle: 'partnershipPartnersWorldwide',
        image: 'LogoSigmaSoftware.png',
        backgroundImage: '#fff',
        competenciesTitle: 'partnershipPartnersCompetenciesTitle',
        competencies: {
          ru: [
            '- 18+ лет опыта в предоставлении программных корпоративных решений',
            '- UI разработка, ориентированная на удобство пользователя',
            '- Системная интеграция для комплексной автоматизации процессов',
            '- Разработка мобильных приложений, чат-ботов и кроссплатформенных решений',
            '- Big Data консалтинг и разработка платформ данных',
            '- BI, Data Analytics & визуализация решений, управляемых базами данных' ,
            '- Сервис инфраструктурыDevOps & Cloud',
            '- CyberSecurity консалтинг, аудит и тренинги',
          ],
          en: [
            '- 18+ years of expertise in delivering enterprise-level software solutions',
            '- User-centric UI development with focus on usability',
            '- Systems Integration for end-to-end process automation',
            '- Mobile applications, Chat Bots and cross platform solutions development',
            '- Big Data consulting & data platforms development',
            '- BI, Data Analytics & visualization for data-driven decisions',
            '- DevOps & Cloud Infrastructure services',
            '- CyberSecurity consulting, audits & trainings',
          ]
        },
        button: {
          title: 'partnershipPartnersButton',
          link: 'https://sigma.software/',
        }
      },
      {
        title: 'Confero',
        subTitle: 'partnershipPartnersEuropeCIS',
        image: 'confero-logo.png',
        backgroundImage: '#fff',
        competenciesTitle: 'partnershipPartnersCompetenciesTitle',
        competencies: {
          ru: [
            '- Опыт работы с банковскими технологиями',
            '- BPM, CRM',
            '- Compliance решения (KYC / AML)',
            '- Разработка удаленных каналов (Internet Bank, Mobile application, Open Banking, Online Office)',
            '- Интеграция сложных систем и автоматизированных интерфейсов',
            '- Профессиональное QA',
            '- Услуги инфраструктуры и хостинг',
          ],
          en: [
            '- High level professionals with banking IT background and experience',
            '- Business process management, CRM',
            '- Compliance (KYC / AML) solutions based on platforms provided by market leaders',
            '- Remote servicing channels development (Internet Bank, Mobile application, Open Banking, Online Office)',
            '- System integration and automated interfaces',
            '- Professional testing and quality assurance',
            '- Infrastructure services and hosting',
          ]
        },
        button: {
          title: 'partnershipPartnersButton',
          link: 'https://www.confero.tech/',
        }
      },
      {
        title: 'Autofinity',
        subTitle: 'partnershipPartnersWorldwide',
        image: 'Autofinity-logo.png',
        backgroundImage: '#fff',
        competenciesTitle: 'partnershipPartnersCompetenciesTitle',
        competencies: {
          ru: [
            '- Low-Code разработка',
            '- Разработка веб-приложений',
            '- E-Commerce',
            '- Автоматизация маркетинга',
            '- Автоматизация продаж',
            '- Автоматизация биллинга',
            '- Управления бизнес-процессами',
            '- Интеграция CRM, BPM, ERP',
            '- Cloud Automation интеграция',
            '- E-Commerce интеграция',
            '- API / Webhook Orchestratio'
          ],
          en: [
            '- Low-Code Development',
            '- CRM, BPM, ERP Development',
            '- Web App Development',
            '- E-Commerce Development',
            '- Marketing Automation',
            '- Sales Automation',
            '- Billing Automation',
            '- Business Process Automation',
            '- CRM, BPM, ERP Integration',
            '- Cloud Automation Integration',
            '- E-Commerce Integration',
            '- API/Webhook Orchestration',
          ]
        },
        button: {
          title: 'partnershipPartnersButton',
          link: 'https://www.autofinity.io/',
        }
      },

      {
        title: 'D2 Digital',
        subTitle: 'partnershipPartnersUkraine',
        image: 'd2.png',
        backgroundImage: '#fff',
        competenciesTitle: 'partnershipPartnersCompetenciesTitle',
        competencies: {
          ru: [
            '- Автоматизация мартетинга',
            '- Цифровизация бизнеса',
            '- Разработка чат-ботов'
          ],
          en: [
            '- Marketing Automation',
            '- Web/Mob Development',
            '- Chat-bot Development'
          ]
        },
        button: {
          title: 'partnershipPartnersButton',
          link: 'http://d2.digital/',
        }
      },
      {
        title: 'Evergreen',
        subTitle: 'partnershipPartnersUkraine',
        image: 'evergreen.png',
        backgroundImage: '#fff',
        competenciesTitle: 'partnershipPartnersCompetenciesTitle',
        competencies: {
          ru: [
            '- AI & ML (ИИ, компьютерное зрение и машинное обучение)',
            '- Разработка SaaS сервисов и ПО',
            '- Разработка чат-ботов',
            '- Автоматизация процессов',
            '- Интеграции с CRM, ERP, RPA',
          ],
          en: [
            '- CRM, BPM, ERP Integration',
            '- Web/Mob Development',
            '- Chat-bot Development',
            '- Process Automation',
            '- AI, ML, Computer vision'
          ]
        },
        button: {
          title: 'partnershipPartnersButton',
          link: 'https://evergreens.com.ua/ru/',
        }
      },
      {
        title: 'AISales',
        subTitle: 'partnershipPartnersRussia',
        image: 'aisales.png',
        backgroundImage: '#fff',
        competenciesTitle: 'partnershipPartnersCompetenciesTitle',
        competencies: {
          ru: [
            '- AI & ML SaaS диалоговые системы (Разработка NLP/NLU ботов)',
            '- Разработка кнопочных ботов (Telegram, Facebook, VK, Viber, Whatsapp)',
            '- Консультации в автоматизации продаж',
            '- Разработка эффективных скриптов продаж для чат-бота',
            '- Интеграции с CRM, ERP, RPA'
          ],
          en: [
            '- AI & ML SaaS Dialogue Systems (NLP/NLU-based bots development)',
            '- Chat-bot Development (Telegram, Facebook, VK, Viber, Whatsapp)',
            '- Sales Automation Consulting',
            '- Development of effective sales scripts for chat bot',
            '- Integration with CRM, ERP, RPA'
          ]
        },
        button: {
          title: 'partnershipPartnersButton',
          link: 'https://aisales.online/',
        }
      },
      {
        title: 'DigitalBusiness',
        subTitle: 'partnershipPartnersUkraine',
        image: 'digitalbusiness.svg',
        backgroundImage: '#fff',
        competenciesTitle: 'partnershipPartnersCompetenciesTitle',
        competencies: {
          ru: [
            '- Управление бизнес-процессами',
            '- Управление логистическими цепочками',
            '- Управление заказами клиентов',
            '- Формирование данных, аналитика',
          ],
          en: [
            '- Business process management',
            '- Supply chain management',
            '- Customer order management',
            '- Data analytics',
          ]
        },
        button: {
          title: 'partnershipPartnersButton',
          link: 'https://digitalbusiness.com.ua/',
        }
      },
      {
        title: 'Big Data Labs',
        subTitle: 'partnershipPartnersEurope',
        image: 'logoBigdata.png',
        backgroundImage: '#fff',
        competenciesTitle: 'partnershipPartnersCompetenciesTitle',
        competencies: {
          ru: [
            '- Медицина и здравоохранение',
            '- Банки и финансы',
            '- Цифровая трансформация',
            '- Автоматизация процессов',
            '- CRM',
            '- Управления бизнес-процессами',
            '- Управление API',
            '- Автоматизация маркетинга',
          ],
          en: [
            '- Healthcare',
            '- Banking and finance',
            '- Digital transformation',
            '- Process automation',
            '- CRM',
            '- Business process management',
            '- API orchestration',
            '- Marketing automation',
          ]
        },
        button: {
          title: 'partnershipPartnersButton',
          link: 'https://bigdatalabs.net/',
        }
      },
      {
        title: 'Foxteam Digital',
        subTitle: 'partnershipPartnersUkraine',
        image: 'foxteam-logo.png',
        backgroundImage: '#fff',
        competenciesTitle: 'partnershipPartnersCompetenciesTitle',
        competencies: {
          ru: [
            '- Разработка чат-ботов (Telegram, Facebook, Viber, Whatsapp)',
            '- Разработка бизнес-процессов командой профессиональных бизнес-аналитиков',
            '- Интеграция с ERP и CRM системами',
            '- Проведение тренингов по Corezoid для внутренних команд',
            '- Консалтинг в розничных бизнес-процессах',
          ],
          en: [
            '- Chat-bots development (Telegram, Facebook, Viber, Whatsapp)',
            '- Business processes automation by a team of professional business analysts',
            '- Integration with ERP and CRM systems',
            '- Corezoid training for internal teams',
            '- Consulting in retail business processes',
          ]
        },
        button: {
          title: 'partnershipPartnersButton',
          link: 'https://foxteam.digital/',
        }
      },
    ]
  },
  
  certification: {
    title: 'partnershipCertificationTitle',
    subTitle: 'partnershipCertificationSubTitle',
    button: {
      title: 'partnershipCertificationButton',
      link: '/form/certification-partner',
    },
    image: 'medal.png',
  },
  vacancy: {
    title: 'partnershipVacancyTitle',
    subTitle: 'partnershipVacancySubTitle',
    button: {
      title: 'partnershipVacancyButton',
      link: '/form/submit-vacancy',
    },
    image: '1f50d.png',
  }
};


const Partnership = (props) => (
  <Layout {...props}>
    <PartnershipPage data={partnership} />
  </Layout>
);

export default withIntl(Partnership);

