import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'mw-style-react';
import { injectIntl } from 'react-intl';
import './Partnership.scss';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import { Link } from '../../i18n';
import Utils from '../../utils/utils';
import Image from '../../components/Image/Image';

class Partnership extends PureComponent {
  render() {
    const { intl, data } = this.props;

    return (
      <section className="partnership">
        <HeaderPage data={intl.formatMessage({id: data.header})}/>
        <div className="partnership__corPluses">
          <div className="partnership__corPluses__title">
            {intl.formatMessage({id: data.corezoidPluses.title})}
          </div>
          <div className="grid-wrapper partnership__corPluses__list">
            {data.corezoidPluses.list.map((item, index) => (
              <div key={index} className="col-6 partnership__corPluses__item">
                <Image file={item.image}/>
                <div className="partnership__corPluses__item__title">
                  {intl.formatMessage({id: item.title})}
                </div>
                <div className="partnership__corPluses__item__subTitle">
                  {intl.formatMessage({id: item.subTitle})}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="partnership__partners__wrapper">
          <div className="partnership__partners">
            <div className="partnership__partners__title">
              {intl.formatMessage({id: data.partners.title})}
            </div>
            <div className="grid-wrapper partnership__partners__list">
              {data.partners.list.map((item, index) => (
                <div key={index} className="col-4 partnership__partners__item">
                  <div className="partnership__partners__item__header" style={{background: item.backgroundImage}}>
                    <Image file={item.image}/>
                  </div>
                  <div className="partnership__partners__item__title">
                    {item.title}
                  </div>
                  <div className="partnership__partners__item__subTitle">
                    {intl.formatMessage({id: item.subTitle})}
                  </div>
                  <div className="partnership__partners__item__competencies">
                    {intl.formatMessage({id: item.competenciesTitle})}
                    {item.competencies[intl.locale].map((com, index) => (
                      <div key={index} className="partnership__partners__item__competencies__com">
                        {com}
                      </div>
                    ))}
                  </div>
                  <Link
                    to={item.button.link}
                    className="partnership__partners__item__button"
                    target="_blank"
                  >
                    <Button
                      className={'button__default'}
                      label={intl.formatMessage({id: item.button.title})}
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="grid-wrapper partnership__certification">
          <div className="col-9 partnership__certification__content">
            <div className="partnership__certification__title">
              {intl.formatMessage({id: data.certification.title})}
            </div>
            <span
              className="partnership__certification__subTitle"
              dangerouslySetInnerHTML={{
                __html: Utils.bbCodeToHtml(intl.formatMessage({id: data.certification.subTitle}))
              }}
            />
            <Link to={data.certification.button.link} className="partnership__certification__button">
              <Button
                className={'button__default'}
                label={intl.formatMessage({id: data.certification.button.title})}
              />
            </Link>
          </div>
          <div className="col-3 partnership__certification__img">
            <Image file={data.certification.image}/>
          </div>
        </div>
        <div className="partnership__partners__wrapper">
          <div className="partnership__partners grid-wrapper">
            <div className="col-3 partnership__certification__img">
              <Image file={data.vacancy.image}/>
            </div>
            <div className="col-9 partnership__certification__content">
              <div className="partnership__certification__title">
                {intl.formatMessage({id: data.vacancy.title})}
              </div>
              <span
                className="partnership__certification__subTitle"
                dangerouslySetInnerHTML={{
                  __html: Utils.bbCodeToHtml(intl.formatMessage({id: data.vacancy.subTitle}))
                }}
              />
              <Link to={data.vacancy.button.link} className="partnership__certification__button">
                <Button
                  className={'button__default'}
                  label={intl.formatMessage({id: data.vacancy.button.title})}
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}


Partnership.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object
};

export default injectIntl(Partnership);

